import {validateProject} from '../../../apis/web-api';
import { showLoading } from '../../../legacy-scripts/barklib';
import {
	getDefaultPayloadForStartBark,
	getPayloadForStartBarkFromHeader,
} from './create-bark-payload';
import { CreateBarkTracking, logProjectInitialised } from '../../../analytics';
import { CODE_ENTER } from '../../../../constants/key-codes';
import { startBarkV2 } from '../entry-widget/start';

const createBarkTracking = new CreateBarkTracking();

let state = {
	legacyJsPromise: null,
};

function bindCreateBarkListeners() {
	const $categoryInput = $('#category_name');

	// Ensure that this element exists somewhere in the page. It is necessary in order to trigger the new Bark modal from the mobile header search form
	if (!$('#bark_submit').length) {
		$('body').append('<div class="d-none" style="display:none;" id="bark_submit"></div>');
	}

	$(document).on(
		'click',
		'#bark_submit, .init-bark-create, .js-search-professional-mobile',
		(e) => {
			e.preventDefault();

			const defaultPayload = getDefaultPayloadForStartBark();

			launchBarkModal(defaultPayload);
		},
	);

	$('#bark_submit_top').click((e) => {
		e.preventDefault();
		$('.bark-form-top').find('small').hide();
		launchBarkModal(getPayloadForStartBarkFromHeader());
	});

	$categoryInput.on('keydown', () => {
		state.legacyJsPromise = loadLegacyCreateBarkJs();
	});

	$('#postcode_top, #category_name_top').keydown(function (e) {
		if (e.key === CODE_ENTER) {
			$('#bark_submit_top').trigger('click');
		}
		createBarkTracking.logStartBark();
	});

	$('#postcode, #category_name').on('keydown.set-mode', function () {
		if ($('#bark_mode').val() !== 'undefined' && $('#bark_mode').val() == 'dashboard') {
			$('#bark_mode').val('typed-dash');
		}
		createBarkTracking.logStartBark();
	});

	$categoryInput.on('keydown.handle-enter', function (e) {
		if (e.key === CODE_ENTER) {
			// If there is a postcode field, focus to it. Otherwise, submit
			if ($('#postcode:visible').length) {
				$('#postcode').focus();
				e.preventDefault();
				e.stopPropagation();
				return false;
			} else {
				$('#bark_submit').trigger('click');
			}
		}
	});

	$('#postcode').on('keydown.handle-enter', function (e) {
		if (e.key === CODE_ENTER) {
			// If the category has been set, submit the form
			if ($('#category_id').val()) {
				$('#bark_submit').trigger('click');
			}
		}
	});

	// Unbind the back button, just in case we've overridden it (TenderBrowse)
	$('.inline-bark-btn-back').off('click');
}

export const launchBarkModal = async (createBarkFormData) => {
	let payloadJson = {};
	createBarkFormData.forEach((value, key) => (payloadJson[key] = value));

	window.Bark.hideLoading();
	let locData = null;
	const { postcode_id, postcode_type } = payloadJson;

	if (
		(postcode_id > 0 && postcode_type !== -1) ||
		(postcode_type === 'goog' && postcode_id)
	) {
		locData = {
			id: postcode_id,
			type: postcode_type,
		};
	}

	const matchedSeller = window.Bark.get_cookie('publicProfileIdhBarkSrc') || null;

	const initData = {
		locationData: locData,
		category_id: payloadJson.category_id || QS.getValue('category_id'),
		origin: payloadJson.bark_mode,
		bark_mode: payloadJson.bark_mode,
		country_id: window.Bark.ENV.ccid,
		campaign: window.Bark.ENV.campaign,
		matchedSellerHashes: matchedSeller && matchedSeller !== 'null' ? [matchedSeller] : [],
	};

	return startBarkV2(initData);
};

export const validateProjectAndOpenQuestionFlow = (createBarkFormData) => {
	showLoading();
	createBarkTracking.setCategoryId(createBarkFormData['category_id']);

	if (
		Object.keys(window.Bark.createBark).length === 0 &&
		window.Bark.createBark.constructor === Object
	) {
		state.legacyJsPromise = loadLegacyCreateBarkJs();
	}

	//Add owen's functions in here.
	validateProject(createBarkFormData)
		.then((data) => {
			let { legacyJsPromise } = state;
			if (legacyJsPromise) {
				legacyJsPromise.then(({ initLegacyCreateBark }) => {
					initLegacyCreateBark().then(function () {
						openQuestionAnswerFlow(data, createBarkFormData);
					});
				});
			} else {
				openQuestionAnswerFlow(data, createBarkFormData);
			}
		})
		.catch((error) => {
			console.log(error);
		});
};

const openQuestionAnswerFlow = async (validateProjectResponse, createBarkFormData) => {
	const { createBark } = window.Bark;

	if (createBark.showModal) {
		createBark.showModal({ data: validateProjectResponse });

    if (validateProjectResponse?.values) {
			logProjectInitialised({
				category_id: createBarkFormData.get('category_id'),
				pre_bark_id: validateProjectResponse.values.preBarkId,
				country_id: validateProjectResponse.values.bark_country_id,
				location_id: validateProjectResponse.values.locationId,
				campaign: window?.Bark?.ENV?.campaign,
			});
		}
	}
};

export const startBark = ({ payload = getDefaultPayloadForStartBark() } = {}) => {
	validateProjectAndOpenQuestionFlow(payload);
};

const loadLegacyCreateBarkJs = () => {
	return import('../../../legacy-create-bark/start-create-bark');
};

export function initBarkEntryService() {
	bindCreateBarkListeners();
}
