//init
export { initBarkCoreLight as init } from './src/components/start/core-light';

//tracking
export {
	TrackingHeap,
	BarkTracking,
	EntityTracking,
	BuyerProjectInteractionTracking,
	tracking,
	logProjectInitialised,
} from './src/components/analytics';

export { setupDynamicPostCodeAutocomplete } from './src/components/autocomplete-inputs/postcode-autocomplete';

export { AutofillPostcode } from './src/components/autofill-location';

export * from './src/helpers/request-quote/request-quote-from-seller';
export * from './src/helpers/response-actions';

export * from './src/components/apis/buyer-api';
export * from './src/components/reviews';
export * from './src/components/pagination';
export * from './src/constants/key-codes';
export {
    isUSTaxComplianceActive,
    isUSTaxCompliant,
    continueToBusinessAddress,
    saveBusinessAddress,
    initializeGooglePlacesAutoComplete
} from './src/helpers/us-tax-compliance';

export { getLinkAttribution } from './src/helpers/link-attribution';

export { getPayloadForPopupTrue, convertToFormData } from './src/components/new-request-flow/components/entry-service/create-bark-payload';
export {
	validateProjectAndOpenQuestionFlow,
	startBark,
	launchBarkModal
} from './src/components/new-request-flow/components/entry-service/start-bark';

export { initLegacyCreateBark } from './src/components/legacy-create-bark/start-create-bark';

export { default as DropZone } from './src/components/legacy-scripts/dropzone';

export { exposeBrowseProjectFunctionsToWindow } from './src/components/legacy-create-bark/experiments/browse-project/browse-project';

export { barkApi } from './src/components/legacy-scripts/barklib';
export { bugsnagClientNotify } from './src/components/bugsnag/bugsnag-client';

export {
	showChangePasswordModal,
	runPasswordModal,
} from './src/components/change-password/change-password';

export {
	loadUserMFAPromptModal,
	showUserMFAPromptModal
} from './src/components/user-mfa-check/mfa-model';

export { initChameleon } from './src/components/chameleon/init';
export {
	trackLeadsPageSignUp,
	trackProjectHiredStatusChange,
} from './src/components/chameleon/track';
export { sendChameleonAndHeapTrackEvent } from './src/components/chameleon/helpers/tracking';
export {
	autoOpenLauncherAfterSeconds,
	trackLauncherOnPageAfterSeconds,
} from './src/components/chameleon/launcher';

export { initI18n, useNamespace, _t, _l } from './src/libs/i18next';
export { CameraUtil } from './src/components/webcam';
export { PriorityNavManagerLib } from './src/components/legacy-scripts/priority-nav-manager';
export { FormManager } from './src/components/legacy-scripts/form-manager';
export { default as ModalManager } from './src/components/modals/modal-manager';

export { number_format } from './src/helpers/number-formatting';
export { PubSub } from './src/helpers/pubsub';
export { SellerNotificationsV2 } from './src/components/seller-notifications/seller_notifications';
export { SellerCommsUpdatesHeaderManager } from './src/components/seller-comms-updates/seller_comms_updates_header_manager';
export { BarkBuyerNotifications } from './src/components/legacy-scripts/bark-buyer-notifications';
export { BuyerMessenger } from './src/components/legacy-scripts/bark-messenger-v10';
export * from './src/components/contact-seller';
export { exposeQuickScrollToWindow } from './src/helpers/quickscroll';
export { ActivityLog } from './src/components/activity-log/activity_log';
export { formatPhoneNumber } from './src/helpers/phone-number-formatting';
export { ViewportSize } from './src/helpers/viewport-size';
export { momentLocale, reloadMomentLocale } from './src/libs/moment-with-locales';
export { default as Switch } from './src/components/form/switch';
export { default as Select } from './src/components/form/select';
export { default as EventEmitter } from './src/helpers/event-emitter';
export { default as RequestReplyModal } from './src/components/request-reply/request-reply-modal';

export { createEnquiry } from './src/components/apis/buyer-api';

export { startBarkV2 } from './src/components/new-request-flow/components/entry-widget/start';

export { CsatWidget } from './src/components/csat';

String.prototype.capitalize = function() {
	return this.charAt(0).toUpperCase() + this.slice(1)
}

export { trackEmailPreferences } from './src/helpers/email/email-preferences-tracking';
export { showEnquiriesModal, isEnquiriesBuyerFeedbackModalActive} from './src/helpers/enquiries'

export * from './src/data-access/bark-api';
