import { streamComplete } from '../../helpers/stream-complete';
import { AutofillPostcode } from '../autofill-location';

function fillInAddress(autoComplete) {
	const { place_id } = autoComplete.getPlace();
	$('#postcode_id').val(place_id);
	$('#postcode_type').val('goog');
}

function initGoogleAutoComplete(inputId) {
	// hs hacky fix

	var inputs = document.querySelectorAll(
		'input.postcode-auto:not(.is-autocomplete-ready)',
	);

	Array.prototype.forEach.call(inputs, function (input) {
		addGoogleListenerToInput(input);
	});

	let input = document.getElementById(inputId);
	addGoogleListenerToInput(input);
}

function initDefaultGoogleAutoComplete() {
	const inputs = document.querySelectorAll(
		'input.postcode-auto:not(.is-autocomplete-ready)',
	);

	Array.prototype.forEach.call(inputs, function (input) {
		addGoogleListenerToInput(input);
	});
}

function addGoogleListenerToInput(input) {
	if (input && !$(input).hasClass('is-autocomplete-ready')) {
		const options = {
			componentRestrictions: { country: 'ie' },
		};

		let placesAutoComplete = new google.maps.places.Autocomplete(input, options);
		placesAutoComplete.addListener('place_changed', () =>
			fillInAddress(placesAutoComplete),
		);

		// very very hacky need to remove this
		$(input).keyup((e) => {
			e.target.blur();
			e.target.focus();
		});

		input.classList.add('is-autocomplete-ready');
	}
}

function isIreland() {
	return typeof Bark.ENV !== 'undefined' && Bark.ENV.locale.toLowerCase() === 'ie';
}

export function setupDynamicPostCodeAutocomplete(inputId) {
	if (isIreland()) {
		initGoogleAutoComplete(inputId);
	} else {
		inputId = inputId ? `#${inputId}` : '#postcode-auto';

		streamComplete(inputId, 'location', 'POST', function (item) {
			localStorage.setItem(
				`location_${item.id}`,
				JSON.stringify({
					lng: item.lng,
					lat: item.lat,
					name: item.name,
				}),
			);
			$(inputId).data({ locationData: item });
			$('#postcode_id').val(item.id);
			$('#postcode_type').val(item.type);
		});

		if (typeof Bark.ENV !== 'undefined' && Bark.ENV.JWT !== null) {
			AutofillPostcode(inputId);
		}

		// If there is more than one postcode input in the page we need to set up stream complete again on the inline question
		if (
			$('.postcode-auto').length > 1 &&
			$('.inline-bark-q-text #postcode-auto').length > 0
		) {
			streamComplete(
				'.inline-bark-q-text #postcode-auto',
				'location',
				'POST',
				function (item) {
					localStorage.setItem(
						`location_${item.id}`,
						JSON.stringify({
							lng: item.lng,
							lat: item.lat,
							name: item.name,
						}),
					);
					$('#postcode').data({ locationData: item });
					$('#postcode-auto').data({ locationData: item });
					$('#postcode_id').val(item.id);
					$('#postcode_type').val(item.type);
				},
			);

			if (typeof Bark.ENV !== 'undefined' && Bark.ENV.JWT !== null) {
				AutofillPostcode('.inline-bark-q-text #postcode-auto');
			}
		}
	}
}
