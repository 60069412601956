import { _t } from '../../../../libs/i18next';
import { finishNewRequestFlow } from './new-request-flow';
import { tracking as barkTrackingLogEvent } from '../../../analytics/bark-tracking';

function isValidatePolarisCountry(country_id) {
	const slfCountryIds = [13];
	return slfCountryIds.includes(country_id);
}

function isMarketingPage(lang, locale, location) {
	return location.indexOf(`/${lang}/${locale}/in/`) > -1;
}

function validateCategory(category_id) {
	const categoryError = $('#category_error');
	categoryError.hide();
	categoryError.parent().removeClass('new-error');

	if (!Number.parseInt(category_id, 10)) {
		categoryError.text(`* ${_t('common:select-a-service')}`).show();
		categoryError.parent().addClass('new-error');
		return false;
	}

	return true;
}

// as we don't have an event that would indicate that NRF app is loaded
// let's wait till BarkRequestFlow will appear in window
const waitForBarkRequestFlow = () => {
	return new Promise((resolve, reject) => {
		let attempts = 0;
		let intervalId;

		const attemptResolution = () => {
			if (window.BarkRequestFlow !== undefined) {
				clearInterval(intervalId);
				resolve(window.BarkRequestFlow);
			} else {
				attempts += 1;
				if (attempts >= 80) {
					clearInterval(intervalId);
					reject(
						'BarkRequestFlow is not in "window" for more than eight seconds after startBark call',
					);
				}
			}
		};

		attemptResolution();
		intervalId = setInterval(attemptResolution, 100);
	});
};

const checkPolarisCategory = async (country_id, category_id) => {
	if (!isValidatePolarisCountry(country_id)) {
		return;
	}

	try {
		const { data } = await Bark.api(
			'slf/categories',
			{ caid: category_id },
			null,
			null,
			'GET',
		);
		return data[0];
	} catch (error) {
		bugsnagClient.notify(new Error('Failed to get SLF valid categories'), {
			metaData: error,
		});
		return;
	}
};

const checkPolarisLocation = async (country_id, location_name) => {
	if (!location_name) {
		return;
	}
	try {
		const { data } = await Bark.api(
			'location',
			{ coid: country_id, term: location_name },
			null,
			null,
			'POST',
		);
		return data[0];
	} catch (error) {
		bugsnagClient.notify(new Error('Failed to get SLF location data'), {
			metaData: error,
		});
		return;
	}
};

const checkPolarisCategoryLocation = async (category_id, fullLocationData) => {
	if (!fullLocationData) {
		return false;
	}

	try {
		// the paid:false param is to reintroduce the location restriction to stop organic traffic cannibalisation thats affecting lead gen volumes
		const { data } = await Bark.api(
			'slf/available-location',
			{
				caid: category_id,
				lat: fullLocationData.lat,
				lng: fullLocationData.lng,
				paid: 0,
			},
			null,
			null,
			'GET',
		);
		return data;
	} catch (error) {
		bugsnagClient.notify(new Error('Failed to get SLF available location'), {
			metaData: error,
		});
		return false;
	}
};

const redirectToSLF = async (country_id, category_id, locationData) => {
	//Logged in users should not be redirected to SLF
	if (window.Bark.USER?.userId) return false;

	if (isSLFTenderReferrer()) return false;

	const { web_hostname, lang, locale } = Bark.ENV;

	if (isMarketingPage(lang, locale, window.location.href)) return false;

	const categoryData = await checkPolarisCategory(country_id, category_id);
	if (!categoryData?.is_polaris_category) return false;

	const categoryNameMap = {
		'dj-hire': 'dj',
	};

	let categoryNameUrl = categoryNameMap[categoryData.name_url] || categoryData.name_url;

	const baseUrl = `https://${web_hostname}/${lang}/${locale}/m/${categoryNameUrl}/?is_organic=true`;

	if (!locationData) {
		barkTrackingLogEvent(
			'Seller_list_first',
			'Valid Polaris Category - Organic Redirect to SLF With No Location',
			{
				categorySelected: categoryData.name,
				categoryId: categoryData.category_id,
				referrerUrl: window.location.href,
			},
		);
		localStorage.setItem('polarisReferrerUrl', window.location.href);
		window.location.href = baseUrl;
		return true;
	}

	// Only call the API if the location is not in the local storage
	const fullLocationData =
		JSON.parse(localStorage.getItem(`location_${locationData.id}`)) ||
		(await checkPolarisLocation(country_id, $('#postcode-auto').val()));

	const hasValidPolarisCategoryLocation = fullLocationData
		? await checkPolarisCategoryLocation(category_id, fullLocationData)
		: false;

	if (hasValidPolarisCategoryLocation) {
		barkTrackingLogEvent(
			'Seller_list_first',
			'Valid Polaris Category Location Pair - Organic Redirect to SLF With Location',
			{
				categorySelected: categoryData.name,
				categoryId: categoryData.category_id,
				locationSelected: fullLocationData.name,
				referrerUrl: window.location.href,
			},
		);
		localStorage.setItem('polarisReferrerUrl', window.location.href);
		window.location.href = `${baseUrl}&location=${fullLocationData.name}`;
		return true;
	}

	return false;
};

export function isSLFTenderReferrer() {
	// get sId from url
	const urlParams = new URLSearchParams(window.location.search);
	const myParam = urlParams.get('sId');
	// if sId is set return true
	if (myParam && myParam.length > 0) {
		return true;
	}
	return false;
}

export async function startBarkV2({
	locationData,
	category_id,
	country_id,
	campaign,
	user,
	bark_mode,
	matchedSellerHashes,
}) {
	if (!validateCategory(category_id)) {
		return;
	}

	if (locationData !== null && typeof locationData === 'object') {
		if ([-1, '-1'].includes(locationData.id)) {
			locationData = null;
		}
	}

	try {
		try {
			const redirectToSlf = await redirectToSLF(country_id, category_id, locationData);

			if (redirectToSlf) {
				return;
			}
		} catch (error) {
			bugsnagClient.notify(new Error('Failed to redirect to SLF'), {
				metaData: error,
			});
		}

		await waitForBarkRequestFlow();

		const { remove, startNewRequestFlowV2 } = window.BarkRequestFlow;

		return startNewRequestFlowV2({
			locationData,
			category_id,
			country_id,
			campaign,
			origin,
			bark_mode,
			matchedSellerHashes,
			hasModal: true,
			location: '#new-request-flow-widget',
			// onFinish: (newRequestOutput, createBarkResponse) => {
			// Dont think we need this anymore as NRF handles navigation itself, see actions.ts
			// 	finishNewRequestFlow(newRequestOutput, createBarkResponse);
			// },
			onExit: () => remove({ location: '#new-request-flow-widget' }),
			barkInfo: {
				timeZone: window.Bark.ENV.tz,
			},
			siteInfo: {
				locale: window.Bark.ENV.locale,
				lang: window.Bark.ENV.lang,
				JWT: window.Bark.ENV.JWT,
			},
			userData: user,
		});
	} catch (e) {
		bugsnagClient.notify(new Error('Failed to start NRF app V2'), { metaData: e });
	}
}
