export function safeUrl(url) {
	try {
		const urlUpdate = new URL(url);
		const removeParams = ['sli', 'slt', 'bli', 'blt'];
		removeParams.forEach((item) => {
			urlUpdate.searchParams.delete(item);
		});

		return urlUpdate.href;
	} catch (e) {
		window.bugsnagClient &&
			window.bugsnagClient.notify(new Error('Failed to build safe URL'), {
				metaData: { error: e, url },
			});
		return null;
	}
}
