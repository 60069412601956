import ModalManager from '../modals/modal-manager';


export const showUserMFAPromptModal = () => {
	const userMFA = window.Bark?.USER?.MFARequired;
	const hasURI = window.Bark?.USER?.MFAManagementURI;
	const isAdmin = window.Bark?.USER?.isAdmin;
	const hasDismised = window.Bark.get_cookie('skippedMFAPrompt') === '1';
	const hasAttempted = window.Bark.get_cookie('completedMFAPrompt') === '1';

	return userMFA && hasURI && !hasDismised && !hasAttempted && !isAdmin;
};

export const loadUserMFAPromptModal = () => {
	const handleMFAPromptProceed = () => {
		tracking('User MFA Prompt', 'proceed', {
			userId: window.Bark?.USER?.userId,
		});
		window.Bark.set_cookie('completedMFAPrompt', 1, 1, '/');
		window.location.href = window.Bark?.USER?.MFAManagementURI;
	}

	const handleMFAPromptExit = (action) => {
		tracking('User MFA Prompt', action, {
			userId: window.Bark?.USER?.userId,
		});
		window.Bark.set_cookie('skippedMFAPrompt', 1, 1, '/');
	}

	const modalManager = new ModalManager();
	tracking('User MFA Prompt', 'view', {
		userId: window.Bark?.USER?.userId,
	});

	modalManager
		.quickModal(
			_t('common_mfa_prompt:title'),
			_t('common_mfa_prompt:secondary'),
			`<div class="d-flex flex-column justify-content-center align-items-center text-center w-100">` +
				`<img src="https://d18jakcjgoan9.cloudfront.net/images/icons/mfa-prompt-content-image.svg" alt="" width="158" height="141">` +
				`<p class="mt-3 w-75">` + _t('common_mfa_prompt:description') + `</p>` +
			`</div>`,
			[
				modalManager.cancelLinkHTML(_t('common_mfa_prompt:button-skip')),
				modalManager.submitButtonHTML(_t('common_mfa_prompt:button-proceed')),
			],
			['modal-dialog'],
			'userMFAPrompt',
		)
		.on('click', '.modal-submit', {}, function () {
			handleMFAPromptProceed();
		})
		.on('click', '.modal-back-link', {}, function () {
			handleMFAPromptExit('skip');
		})
		.on('click', '.close-modal-cross', {}, function () {
			handleMFAPromptExit('closed');
		});
};
