import { barkApi, apiVersionHeader } from "../../legacy-scripts/barklib";
import { PaginationManager } from "../../pagination";

export default function() {
    const { Bark, Handlebars } = window;

    /**
     * A flag to say whether a form is dirty or not
     * @type {boolean}
     */
    let formDirty = false;

    const init = () => {
        reviewInitiliaze();
        addEventListeners();
    }

    const reviewInitiliaze = () => {
        if ($('.js-review-paginated-page').length > 0) {
            paginate(1);
        }
    }

    const paginate = (page) => {
        let nPages = $('.js-review-paginated-page').length;

        if (nPages < 2) {
            return;
        }

        new PaginationManager().output('#review-collection-pagination', $('.js-review-paginated-page').length, page, function(e) {
            $('.js-review-paginated-page').addClass('d-none');
            $('.js-review-paginated-page-' + e).removeClass('d-none');
            scrollToSection('reviews');
            paginate(e);
        });
    }

    /**
     * Scroll to a named section
     * @param {string} sectionName
     */
    const scrollToSection = (sectionName) => {
        var top = ($('.js-section-' + sectionName).offset() || {}).top || 0,
            isDesktopLayout = $(window).width() >= 1024,
            offset = isDesktopLayout ? 90 : 140; // larger offset for fixed mobile nav

        if (isDesktopLayout) {
            $('.js-profile-nav-container .active-link').removeClass('active-link');
            $('.js-nav-link-' + sectionName).addClass('active-link');
        }

        highlightNavSection(sectionName);

        $('body,html').finish().animate({scrollTop: Math.max(top - offset, 0)}, 400, 'linear', () => {});
    }

    const highlightNavSection = sectionName => {
        $('.js-profile-nav-container .active-link:not(.js-nav-link' + sectionName + ')').removeClass('active-link');
        $('.js-nav-link-' + sectionName).addClass('active-link');
        $("#priority-nav .dropdown-menu a").removeClass('active-link');
        $("#priority-nav .dropdown-menu a[data-section=" + sectionName + "]").addClass('active-link');
        $("#priority-nav .nav-item.more")[$("#priority-nav .dropdown-menu a.active-link").length > 0 ? "addClass" : "removeClass"]('active-link');
    };

    const starClasses = (start, end) => {
        let classes = '';

        for (let i = start; i <= end; i++) {
            classes = classes + '._st' + i + ((i < end) ? ', ' : '');
        }

        return classes;
    }

    const validateReview = (payload) => {
        let formHasErrors = false;

        if (!payload.rating || parseInt(payload.rating) < 1 ) {
            $('#review_user_rating-error').text(_t("seller_profile:add-review-modal.rating-field-required-error")).show();
            formHasErrors = true;
        }

        if (!payload.userid) {
            if (!window.Bark.validateEmailFormat(payload.email)) {
                Bark.showFormErrorsBootstrap('review_user_email', _t("seller_profile:add-review-modal.form.email.invalid-error"));
                formHasErrors = true;
            }

            if (!payload.name || payload.name.length < 1) {
                Bark.showFormErrorsBootstrap('review_user_name', _t("seller_profile:add-review-modal.form.name.required-error"));
                formHasErrors = true;
            }
        }

        if (!payload.review || payload.review.length < 1) {
            Bark.showFormErrorsBootstrap('review_user_review', _t("seller_profile:add-review-modal.form.review.required-error"));
            formHasErrors = true;
        }

        return !formHasErrors;
    }

    /**
     * On cancel/save of the add new review form, clear the form
     */
    const resetReviewForm = () => {
        $('.js-click-to-rate-stars').addClass('text-grey-400');
        $('#add-review-modal :input').val('');
        $('#add-review-modal').modal('hide');

        // Using .hide and .show because the function $.fn.fadeIn is used
        $('#_rvloading').hide();
        $('#_rvsuccess').hide();
        $('#_rvform').show();
        $('#add-review-modal .modal-footer').show();
    }

    const getRecaptchaToken = () => {
		return new Promise((resolve, reject) => {
			try {
				const keyId = $('#ml-recaptcha-script').data('key');
				grecaptcha.enterprise.ready(async () => {
					try {
						const token = await grecaptcha.enterprise.execute(keyId, {
							action: 'post_review_check',
						});
						resolve(token);
					} catch (e) {
						reject();
					}
				});
			} catch (e) {
				reject();
			}
		});
	};

	const postSellerReview = async () => {
		$('.form-control').removeClass('is-invalid');
		$('#review_user_rating-error').text('').hide();

		let payload = {
			spidh: $('#spidh-hv').val(),
			userid: +$('#add-review-logged-in-user-id').val() || null,
			name: ($('#review_user_name').val() || '').trim(),
			email: ($('#review_user_email').val() || '').trim(),
			review: $('#review_user_review').val().trim(),
			rating: +$('#star-rating-amount').val(),
			usedcompany: 0,
		};

		if (!validateReview(payload)) {
			return;
		}
		const captchaToken = await getRecaptchaToken();
		payload.captchaToken = captchaToken;
		payload.platform = 'web';

		if (payload.userid) {
			payload.usedcompany = +$('#review_user_used_company').is(':checked');
		}

		// Using .hide and .show because the function $.fn.fadeIn is used
		$('#_rvform').hide();
		$('#add-review-modal .modal-footer').hide();
		$('#_rvloading').show();

		barkApi(
			'seller/post-review',
			payload,
			function (e) {
				// Using .hide and .show because the function $.fn.fadeIn is used
				$('#_rvloading').hide();

				if (e.status) {
					formDirty = false;
					window.ga?.('send', 'event', 'backend', 'seller_add_review');

					if ($('#closed-bark-form').length > 0) {
						window.location = _l('/buyers/dashboard/?close-bark-confirm=review');
					} else {
						$('#_rvform, #add-review-modal .modal-footer').fadeOut(function () {
							$('#_rvsuccess').fadeIn();
						});
					}
					return;
				} else {
					$('#_rvloading').hide();
					$('#_rvform,  #add-review-modal .modal-footer').fadeIn();
					let errors = e.errors;

					for (let key in errors) {
						let msg = _t('seller_profile:add-review-modal.field-required-error');

						if (errors[key]) {
							msg = errors[key].msg;
						}
						Bark.showFormErrorsBootstrap(errors[key].fieldname, msg);
					}
				}
			},
			function (e) {
				// Using .hide and .show because the function $.fn.fadeIn is used
				$('#_rvloading').hide();
				$('#_rvform,  #add-review-modal .modal-footer').fadeIn();

				let error = e.responseJSON.error;

				for (let key in error.errors) {
					let msg = _t('seller_profile:add-review-modal.field-required-error');

					if (error.errors[key]) {
						msg = error.errors[key][0];
					}

					Bark.showFormErrorsBootstrap('review_user_' + key, msg);
				}
			},
			'POST',
			apiVersionHeader('v2'),
		);
	};

	const addEventListeners = () => {
		let class_dark_star = 'text-grey-400';
		let inpStar = $("input[id='star-rating-amount']");

		$(starClasses(1, 5)).hover(
			function () {
				$(starClasses(1, 5)).removeClass(class_dark_star);
				if ($(this).hasClass('_st1')) {
					$(starClasses(2, 5)).addClass(class_dark_star);
				}
				if ($(this).hasClass('_st2')) {
					$(starClasses(3, 5)).addClass(class_dark_star);
				}
				if ($(this).hasClass('_st3')) {
					$(starClasses(4, 5)).addClass(class_dark_star);
				}
				if ($(this).hasClass('_st4')) {
					$(starClasses(5, 5)).addClass(class_dark_star);
				}
			},
			function () {
				$(starClasses(1, 5)).removeClass(class_dark_star);
				for (let i = 0; i < 5; i++) {
					if (inpStar.val() == i) {
						$(starClasses(i + 1, 5)).addClass(class_dark_star);
					}
				}
			},
		);

		$(starClasses(1, 5)).click(function () {
			for (let i = 1; i <= 5; i++) {
				if ($(this).hasClass('_st' + i)) {
					inpStar.val(i);
				}
			}

			$(starClasses(1, 5)).removeClass(class_dark_star);

			for (let i = 0; i < 5; i++) {
				if (inpStar.val() == i) {
					$(starClasses(i + 1, 5)).addClass(class_dark_star);
				}
			}
		});

		$('.js-post-review')
			.off('click.submit-review')
			.on('click.submit-review', postSellerReview);

		$('.js-close-review-modal')
			.off('click.close')
			.on('click.close', function () {
				if (formDirty) {
					$('#add-review-modal').modal('hide');
					Bark.confirmModal(
						_t('seller_profile:add-review-modal.confirm-close-modal-message'),
						function () {
							resetReviewForm();
							formDirty = false;
						},
						function () {
							$('#add-review-modal').modal('show');
						},
					);
				} else {
					resetReviewForm();
				}
			});

		$('#add-review-modal :input')
			.off('change.setdirty')
			.on('change.setdirty', function () {
				formDirty = true;
			});

		if ($('.js-review-filter').length > 0) {
			$('.js-review-filter')
				.off('change.filter')
				.on('change.filter', function () {
					getReviews(1);
				});
		}

		addMoreTextListener();
	};

    const addMoreTextListener = () => {
        $('.js-show-more-review-text').off('click.showmore').on('click.showmore', function () {
            var t = $(this);
            var scope = t.closest('.js-review-text-container');

            $('.js-more-review-text', scope).removeClass('d-none');
            scope.removeClass('no-line-break');
            t.remove();
        });
    }

    const filterReviewText = (reviewText, firstSection) => {
        if (!reviewText) return '';
        if (reviewText.length < 120) return firstSection ? reviewText : '';
        let regex = /((?:.|\s){120}[^\s\.\?\!\-\,\)\]\;\:\n]*)((?:.|[\n\s])*)/
        let parts = reviewText.match(regex);
        if (parts) {
            return parts[firstSection ? 1 : 2];
        } else {
            return firstSection ? reviewText : '';
        }
    }

    const formatReviewDate = (reviewDate) => {
        return reviewDate ? new Date(reviewDate.split(' ')[0]).toLocaleDateString(
            'en-'+Bark.LOCALE.iso2c,
            {
                year: 'numeric',
                month: 'long',
                day: 'numeric'
            }
        ) : '';
    }

    const getReviews = (page) => {
        // collect active filters
        let scores ='';
        $('.js-review-filter:checked').each(function() {
            if (scores) scores+= ','
            scores += $(this).val();
        });
        if (!scores) {
            scores = '1,2,3,4,5';
        }
        $('.reviews-parent').addClass('waiting');
        barkApi(
            'sellerprofile/'+ $('#spidh-hv').val() + '/reviews/' + page +'/',
            { 'scores': scores },
            function(data) {
                if ($("#review-item-template").length < 1) {
                    // todo bugsnag? show error?
                    return;
                }
                let reviewsContainer = $('.review-collection-container');
                let reviewItemTemplate = Handlebars.compile(document.getElementById("review-item-template").innerHTML);
                let currentPage = data.data.current_page;
                let lastPage = data.data.last_page;
                let reviews = data.data.items;
                let newReviews = [];
                for(let j =0;  j < reviews.length; j++) {
                    let review = reviews[j];
                    if (typeof review.response === 'undefined') {
                        review.response = false;
                    }
                    newReviews.push(reviewItemTemplate({
                        name: review.review_author,
                        score: review.score * 2.0,
                        score_round: Math.round(review.score),
                        project_category: review.project_category,
                        created_date: formatReviewDate(review.review_date),
                        text_main: filterReviewText(review.content, true),
                        text_more: filterReviewText(review.content, false),
                        source_name: review.source_name,
                        response: review.seller_response,
                    }));
                }
                reviewsContainer.parent().removeClass('waiting');
                reviewsContainer.empty()
                    .append(newReviews.join(' '))
                    .append($('<div>').prop('id', 'review-collection-pagination').addClass('mt-4'));
                addMoreTextListener();
                new PaginationManager().output(
                    reviewsContainer.find('#review-collection-pagination'),
                    lastPage,
                    currentPage,
                    function(page) {
                        if(!reviewsContainer.parent().hasClass('waiting')){
                            reviewsContainer.parent().addClass('waiting');
                            getReviews(page);
                        }
                    }
                );
                scrollToSection('reviews');
            },
            function(error) {
                console.error(error);
            },
            'GET',
        )
    }

    $(document).ready(() => {
        init();
    })
}

